<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    wrapClassName="edit-folder-modal"
  >
    <div class="edit-folder-container">
      <h1 class="edit-folder-container__title">Edit {{ selectedFolderName }}</h1>
      <input
        type="text"
        v-model="selectedFolder"
        class="edit-folder-container__input"
        placeholder="Enter name"
      />
      <p v-if="error" class="edit-folder-container__error">{{ error }}</p>
      <TagifyContacts
        placeholder="Invite Your Contacts"
        :options="options"
        @fetchSelection="fetchedSelection"
        :isSubmitted="isSubmit"
        :removeFields="emptyTagifyInput"
        id="findUsers"
        :prevTags="sharedWith"
      ></TagifyContacts>
      <a-button
        :loading="loading"
        class="edit-folder-container__btn"
        @click="takeAction"
        >Save</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import TagifyContacts from "../../PdbComponents/TagifyContacts.vue";

export default {
  components: {
    TagifyContacts
  },
  props: {
    visible: Boolean,
    selectedFolderName: String,
    selectedFolderId: String,
    loading: Boolean,
  },
  data() {
    return {
      selectedFolder: "",
      error: "",
      options: [],
      selectedUsers: [],
      isSubmit: false,
      emptyTagifyInput: false,
      sharedWith: [],
      usersToBeRemove: []
    };
  },
  watch: {
    selectedFolderName() {
      this.selectedFolder = this.selectedFolderName;
      this.folderId = this.selectedFolderId;

    },
    async visible(val){
      if(val){
        this.sharedWith = [];
        this.emptyTagifyInput = true;
        await this.fetchSharedUsers()
        await this.fetchContacts();
        this.emptyTagifyInput = false;
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("handle-close", false);
    },
    takeAction() {
      this.selectedUsers = [...new Set(this.selectedUsers)]
      if (this.selectedFolder == "") {
        this.error = "This field can not be empty";
        return false;
      } else {
        this.error = "";
      }
      const payload = {
        folderName: this.selectedFolder,
        connectionList: this.selectedUsers,
      }
      this.$emit("edit-record", payload);
      this.isSubmit =  true;
      this.isSubmit =  false;
    },
    async fetchContacts() {
      try {
        this.options = [];
        const payload = {
          user_id: this.$store.getters["auth/getUserId"],
        };
        const response = await this.$store.dispatch(
          "profile/getFriendsList",
          payload
        );
        if (response.status === 200) {
          this.options = response.data.friends.map((val) => {
            if (val.profile) {
              let userList = {
                label: val.profile.full_name,
                value: val.id,
                key: val.email,
                icon: val.profile.profile_picture,
              };
              return userList;
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchSharedUsers(){
      const payload = {
        user_id: this.$route.params.id,
        folder_id: this.selectedFolderId
      }
      const response = await this.$store.dispatch("article/fetchSavedArticlesList", payload)
      
      if(response.status === 200){
        this.sharedWith = [...response.data.invitations.map(contact=>{
        return contact.invite_to.id;
      })]
      }
    },
    fetchedSelection(val , userId){
      if(userId){
        console.log(userId)
        this.usersToBeRemove.push(userId)
        this.revokeUserFromFolder();
      }

      this.selectedUsers = val.map(contact=>{
        if(!this.sharedWith.includes(parseInt(contact.value))){
          return contact.value
        } 
      })

      this.selectedUsers = this.selectedUsers.filter(user=>user)
    },
    async revokeUserFromFolder(){
      try{
        const payload = {
          id: this.selectedFolderId,
          joined_users: this.usersToBeRemove,
        }
        await this.$store.dispatch('article/removeUserFromFolder', payload);
        this.usersToBeRemove = []
      } catch(err){
        console.log(err);
      }
    },
  },
};
</script>


<style lang="scss">
.edit-folder-modal {
  .ant-modal {
    width: 50rem !important;
  }
}
.edit-folder-modal {
  .ant-modal {
    width: 55rem !important;
    .ant-modal-header {
      padding: 0;
      border: none;
      margin-bottom: 1rem;
    }
    .ant-modal-content {
      padding: 3rem;
      border-radius: 0;
      position: relative;
      .ant-modal-close {
        margin-right: 3rem;
        margin-top: 3rem;
        height: fit-content;
        width: fit-content;
        .ant-modal-close-x {
          width: 2rem;
          height: 2rem;
          line-height: 2rem;
          margin-right: 0;
          margin-top: 0;
          .anticon-close {
            svg {
              width: 2rem;
            }
          }
        }
      }
      .ant-modal-body {
        .edit-folder-container {
          &__title {
            font-size: 2.4rem;
            font-family: $font-secondary-bold;
            line-height: normal;
            margin-bottom: 2rem;
          }
          &__error {
            font-size: 1.4rem;
            font-family: $font-primary;
            color: red;
          }
          &__input {  
            background-color: $color-white;
            border: 1px solid rgba(0, 0, 0, 0.2);
            outline: none;
            height: 4rem;
            font-size: 1.6rem;
            font-family: $font-primary;
            color: $color-black;
            padding: 0 1rem;
            width: 100%;
            margin-bottom: 1rem;
            &::placeholder {
              opacity: 0.5;
            }
          }
          &__btn {
            width: 10rem;
            background-color: $color-primary;
            border: none;
            height: 4rem;
            display: block;
            margin-left: auto;
            font-size: 1.6rem;
            font-family: $font-primary;
            color: $color-white;
            margin-top: 3rem;
            span {
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
</style>