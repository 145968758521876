<template>
  <experience-loader v-if="isExperiencecLoading"></experience-loader>
  <div v-else class="work-experience-section">
    <div class="work-experience-section__header">
      <h2 class="work-experience-section__header--title">Work Experience</h2>
      <button
        class="work-experience-section__header--btn"
        v-if="isLoggedInUser"
        @click="addExperience()"
        id="addExperience"
        title="addExperience"
      >
        <i class="icon icon-add_plus"></i>
      </button>
    </div>
    <div class="work-experience-section__body">
      <div
        class="work-experience-section__body--experience"
        v-for="experience in experiences"
        :key="experience"
      >
        <div class="header">
          <div class="header__details">
            <h2>{{ experience.title }}</h2>
            <p>
              {{ experience.organization }} 
              {{ " | " + experience.start_date }} - 
              {{ experience.end_date ? experience.end_date : "Present" }}  
              {{ experiences.location ? " |  " + experiences.location : ''  }} 
              {{ experiences.employment_type ? " · " + experiences.employment_type : ''  }}
            </p>
          </div>
          <div class="header__edit-delete-btn">
            <button
              v-if="isLoggedInUser"
              @click="showDeleteModal(experience.id)"
              class="btn delete-btn"
              id="experiencesDeleteBtn"
              title="experiencesDeleteBtn"
            >
              <i class="icon icon-dustbin"></i>
            </button>
            <button
              v-if="isLoggedInUser"
              @click="editExperience(experience)"
              class="btn edit-btn"
              id="experiencesEditBtn"
              title="experiencesEditBtn"
            >
              <img src="@/assets/icons/edit-icon.svg" width="auto" height="auto" alt="Edit"/>
            </button>
          </div>
        </div>
        <div class="body">
          <ul v-if="!experience.description"></ul>
          <ul v-else>
            <li>
              {{ experience.description }}
            </li>
          </ul>
        </div>
      </div>
      <p
        v-if="!isExperiencecLoading && experiences.length == 0"
        class="message"
      >
        No record added
      </p>
    </div>
  </div>
  <add-experience-modal
    ref="experience-modal"
    :action="action"
    :visible="showAddExperienceModal"
    @close-modal="showAddExperienceModal = false"
    :footer="null"
  ></add-experience-modal>
  <delete-modal
    :visible="deleteModalVisible"
    @handle-close="deleteModalVisible = false"
    title="Are you sure you want to delete this?"
    confirmText="Yes, Delete"
    cancelText="No, Don't Delete"
    :message="null"
    @delete-record="deleteExperience"
    :loading="deleteloading"
  ></delete-modal>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import ExperienceLoader from "./SkeletonLoaders/ExperienceSkeletonLoader.vue";
import AddExperienceModal from "../ProfilePageComponents/AddExperienceModal.vue";
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import MessageToaster from "../BaseComponents/MessageToaster.vue";

export default {
  data() {
    return {
      showAddExperienceModal: false,
      deleteloading: false,
      deleteModalVisible: false,
      showMessageModal: false,
      action: "",
      message: {},
    };
  },
  components: {
    ExperienceLoader,
    AddExperienceModal,
    DeleteModal,
    MessageToaster,
  },
  computed: {
    isExperiencecLoading() {
      return this.$store.getters["profile/getExperienceSkeletonLoader"];
    },
    experiences() {
      return this.$store.getters["profile/getExperiences"];
    },
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
  },
  methods: {
    addExperience() {
      this.action = "add";
      this.showAddExperienceModal = true;
    },
    editExperience(experience) {
      this.action = "edit";
      this.showAddExperienceModal = true;
      this.$refs["experience-modal"].selectExperience(experience);
    },
    async deleteExperience() {
      this.deleteloading = true;
      try {
        const response = await this.$store.dispatch(
          "profile/deleteExperience",
          this.selectedRecordId
        );
        if (response.status === 204) {
          this.message = {
            title: "Deleted Successfully",
            desc: "",
            type: "success",
          };
          this.deleteModalVisible = false;
          this.showMessageModal = true;
        }
      } catch (err) {
        this.message = {
          title: "Something went wrong",
          desc: "Please Try Again later. Sorry for the inconvenience",
          type: "failure",
        };
        this.showMessageModal = true;
        this.deleteloading = false;
      }
      this.selectedRecordId = null;
      this.deleteloading = false;
    },
    showDeleteModal(id) {
      this.selectedRecordId = id;
      this.deleteModalVisible = true;
      this.deleteloading = false;
    },
  },
};
</script>

<style lang="scss">
.work-experience-section {
  padding: 0 2.6rem;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.7rem;
    &--title {
      font-size: 2rem;
      font-family: $font-primary-medium;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      @include respond(tab-port) {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }
    &--btn {
      background-color: $color-primary;
      height: 4.2rem;
      width: 4.2rem;
      border: none;
      outline: none;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon {
        color: $color-white;
        font-size: 1.6rem;
      }
    }
  }
  &__body {
    &--experience {
      padding: 2.4rem 0 0;
      .header {
        display: flex;
        align-items: flex-start;
        &__details {
          width: 100%;
          h2 {
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            line-height: 1.8rem;
            color: $color-black;
            margin-bottom: 2rem;
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
          p {
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 1.8rem;
            color: #6f6f6f;
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
        }
        &__edit-delete-btn {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          .btn {
            line-height: normal;
            width: fit-content;
            height: fit-content;
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            img {
              width: 2.5rem;
              height: auto;
            }
            .icon {
              color: $color-black;
              font-size: 1.8rem;
              display: block;
            }
            @include respond(tab-port) {
              img {
                width: 3rem;
              }
              .icon {
                font-size: 2.3rem;
              }
            }
          }
        }
      }
      .body {
        margin-top: 2.7rem;
        margin-bottom: 3rem;
        ul {
          padding-left: 1.4rem;
          margin-bottom: 0;
          li {
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 1.9rem;
            color: $color-black;
            padding-left: 0.5rem;
            max-width: 90%;
            button {
              color: #6f6f6f;
              border: none;
              outline: none;
              background-color: transparent;
              cursor: pointer;
            }
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
        }
      }
    }
    .message {
      font-size: 1.6rem;
      font-family: $font-primary;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      margin-bottom: 3rem;
    }
  }
}
</style>
