<template>
  <a-modal
    width="50rem"
    @cancel="cancelExperienceModal"
    class="experience-modal-main"
  >
    <div class="experience-modal">
      <h1 class="experience-modal__heading">Add Work Experience</h1>
      <div class="experience-modal__form-fields">
        <a-form
          class="modal-form"
          ref="formRef"
          :label-col="{ style: { width: '150px' } }"
        >
          <a-form-item label-align="left" label="Title">
            <a-input data-cy="workExperienceTitle" placeholder="Enter title" v-model:value="title" />
            <p v-if="error.title" class="error">{{ error.title }}</p>
          </a-form-item>
          <a-form-item label-align="left" label="Organization">
            <a-input
            data-cy="workExperienceOrganization"
              placeholder="Enter organization name"
              v-model:value="organization"
            />
            <p v-if="error.organization" class="error">
              {{ error.organization }}
            </p>
            <a-checkbox
              v-model:checked="isVisibleOrganization"
              class="organization-check"
            >
              Make this my headline
            </a-checkbox>
          </a-form-item>
          <a-form-item label-align="left" label="Description">
            <a-textarea
            data-cy="workExperienceDescription"
              placeholder="Write description"
              v-model:value="description"
              class="description-textarea"
            />
          </a-form-item>
          <a-form-item label-align="left" label="Start Date">
            <a-month-picker
              data-cy="workExperienceStartDate"
              v-model:value="start_date"
              type="date"
              placeholder="Pick start date"
              style="width: 100%"
              format="YYYY-MM"
              :disabled-date="disabledStartDate"
            />
            <p v-if="error.start_date" class="error">{{ error.start_date }}</p>
          </a-form-item>
          <a-form-item
            label-align="left"
            label="End Date"
            style="text-align: left"
          >
            <a-month-picker
            data-cy="workExperienceEndDate"
            mode="month"
              v-model:value="end_date"
              type="date"
              placeholder="Pick end date"
              style="width: 100%"
              format="YYYY-MM"
              :disabled="present"
              :disabled-date="disabledEndDate"
            />
            <a-checkbox v-model:checked="present" class="organization-check">
              Currently working in this role
            </a-checkbox>
            <p v-if="error.end_date" class="error">{{ error.end_date }}</p>
          </a-form-item>
        </a-form>
        <a-button
          data-cy="workExperienceAddButton"
          v-if="action == 'add'"
          :loading="loading"
          class="form-btn"
          @click="addExperience"
        >
          Add
        </a-button>
        <a-button
        data-cy="workExperienceUpdateButton"
          v-else
          class="form-btn"
          :loading="loading"
          @click="updateExperience"
        >
          Update
        </a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { DateTime } from 'luxon';
export default {
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  emits: ["close-modal"],
  data() {
    return {
      id: null,
      title: null,
      organization: null,
      description: null,
      start_date: null,
      present: false,
      isVisibleOrganization: false,
      end_date: null,
      loading: false,
      error: {
        title: "",
        organization: "",
        start_date: "",
        end_date: "",
        present: "",
        isVisibleOrganization: "",
      },
      message: {},
    };
  },
  methods: {
    disabledStartDate(startDate) {
     const currentDate = new Date(); 
      if(startDate._d > currentDate){
        return true;
      }
     return false;
    },
    disabledEndDate(endDate) {
      const currentDate = new Date();
      if (!endDate || !this.start_date) {
        return false;
      } else if (endDate._d > currentDate) {
        return true;
      }
      // Compare with the selected start date to disable dates before it
      return new Date(this.start_date) > new Date(endDate._d);
    },
    selectExperience(experience) {
      let isoDateString = null;
      let experienceDate = null;
      let month = null;
      let year = null;
      // For Start Date 
      // let strDateObject = new Date(experience.start_date);
      // let strYear = strDateObject.getFullYear();
      // let strMonth = strDateObject.getMonth() + 1;
      // let strDate = `${strYear}-${strMonth}`;
      // this.start_date = strDate + '-01';

      // // For End Date
      // let endDateObject = new Date(experience.end_date);
      // let endYear = endDateObject.getFullYear();
      // let endMonth = endDateObject.getMonth() + 1;
      // let endDate = `${endYear}-${endMonth}`;
      // this.end_date = endDate + '-01';


      // const strDateObject = new Date(experience.start_date);
      // const strYear = strDateObject.getFullYear();
      // const strMonth = strDateObject.getMonth() + 1;
      // this.start_date = `${strYear}-${strMonth.toString().padStart(2, '0')}`;

      if (experience.start_date) {
        isoDateString = DateTime.fromFormat(experience.start_date, 'LLL-yyyy').toISODate();
        experienceDate = DateTime.fromISO(isoDateString);
        month = experienceDate.toFormat('MM');
        year = experienceDate.toFormat('yyyy');

        const startDate = `${year}-${month}`;
        this.start_date = startDate;
      }

  
      // For End Date 
      if (experience.end_date) {
        isoDateString = DateTime.fromFormat(experience.end_date, 'LLL-yyyy').toISODate();
        experienceDate = DateTime.fromISO(isoDateString);
        month = experienceDate.toFormat('MM');
        year = experienceDate.toFormat('yyyy');

        const endDate = `${year}-${month}`;
        this.end_date = endDate;
      }


      // const endDateObject = new Date(experience.end_date);
      // const endYear = endDateObject.getFullYear();
      // const endMonth = endDateObject.getMonth() + 1;
      // this.end_date = `${endYear}-${endMonth.toString().padStart(2, '0')}`;

      this.id = experience.id;
      this.title = experience.title;
      this.organization = experience.organization;
      this.description = experience.description;
      this.present = experience.present;
      this.isVisibleOrganization = experience.change_company_name;
    },
    cancelExperienceModal() {
      this.resetErrors();
      this.resetFields();
      this.$emit("close-modal");
    },
    async addExperience() {
      let issueDate = null;
      if (this.start_date == null) {
        this.error.start_date = "This field is required";
        this.error.title = this.title ? "" : "This field is required";
        this.error.organization = this.organization
          ? ""
          : "This field is required";
        this.error.end_date =
          this.present || this.end_date ? "" : "This field is required";
        return;
      } else {
        issueDate = this.start_date;
      }
      issueDate = this.setDateFormat(issueDate);
      let expiryDate = this.present ? null : this.end_date;
      expiryDate = this.setDateFormat(expiryDate);
      let requestObj = {
        title: this.title,
        organization: this.organization,
        start_date: issueDate,
        end_date: expiryDate,
        present: this.present,
        change_company_name: this.isVisibleOrganization,
        experience_type: "work",
        description: this.description,
      };
      this.resetErrors();
      this.loading = true;
      try {
        const response = await this.$store.dispatch(
          "profile/addExperience",
          requestObj
        );
        if (response.status === 201) {
          this.message = {
            showMessageModal: true,
            title: "Experience Added Successfully",
            type: "success",
          };
          this.emitter.emit("visibleMessageToaster", this.message);
          this.resetFields();
          
          // getting updated experinces
          const payload = {
            user_id: this.$store.getters['auth/getUserId']
          };
          await this.$store.dispatch(
            "profile/loadExperiences",
            payload
          );


          this.$emit("close-modal");
        }
      } catch (err) {
        if (err.response.status === 400) {
          Object.keys(err.response.data).forEach((key, i, arr) => {
            err.response.data[key].forEach((val) => {
              this.error[key] += val + "\n";
            });
          });
        } else {
          this.message = {
            showMessageModal: true,
            title: "Something went wrong",
            type: "failure",
          };
          this.cancelExperienceModal();
          this.emitter.emit("visibleMessageToaster", this.message);
        }
      }
      this.loading = false;

    },
    async updateExperience() {
      this.end_date = this.present ? null : this.end_date;
      let requestObj = {
        id: this.id,
        title: this.title,
        organization: this.organization,
        description: this.description,
        experience_type: "work",
        start_date: null,
        end_date: null,
        present: this.present,
        change_company_name: this.isVisibleOrganization,
      };
      
          if (this.start_date) {
            const startDate = new Date(this.start_date);
            requestObj.start_date = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd');
      }
      
      if (this.end_date) {
        const endDate = new Date(this.end_date);
        requestObj.end_date = DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd'); // Format as YYYY-MM-DD
      }
      
      this.resetErrors();
      this.loading = true;
      
      try {
        const response = await this.$store.dispatch(
          "profile/updateExperience",
          requestObj
        );
        
        if (response.status === 200) {
          this.message = {
            showMessageModal: true,
            title: "Experience Updated Successfully",
            type: "success",
          };
          this.emitter.emit("visibleMessageToaster", this.message);
          this.resetFields();
          this.$emit("close-modal");
        }
      } catch (err) {
        if (err.response.status === 400) {
          Object.keys(err.response.data).forEach((key) => {
            err.response.data[key].forEach((val) => {
              this.error[key] += val + "\n";
            });
          });
        } else {
          this.message = {
            showMessageModal: true,
            title: "Something went wrong",
            type: "failure",
          };
          this.cancelExperienceModal();
          this.emitter.emit("visibleMessageToaster", this.message);
        }
      }
      
      this.loading = false;
    },
    resetErrors() {
      this.error = {
        title: "",
        organization: "",
        start_date: "",
        end_date: "",
        present: "",
        description: "",
        isVisibleOrganization: "",
      };
    },
    resetFields() {
      this.id = null;
      this.title = null;
      this.organization = null;
      this.description = null;
      this.start_date = null;
      this.present = false;
      this.end_date = null;
      this.isVisibleOrganization = false;
    },
    // setDateFormat(date) {
    //   date =
    //     typeof date == "object" && date !== null
    //       ? date._d.getFullYear() +
    //         "-" +
    //         (date._d.getMonth() + 1) +
    //         "-" +
    //         date._d.getDate()
    //       : date;
    //   return date;
    // },
    setDateFormat(date) {
  if (typeof date === "object" && date !== null) {
    const year = date._d.getFullYear();
    const month = (date._d.getMonth() + 1).toString().padStart(2, '0');
    return `${year}-${month}-01`;
    
  }
  console.log(date)
  return date;
}
  },
};
</script>
<style lang="scss">
.experience-modal-main {
  top: 8rem;
  @include respond(tab-port) {
    top: 5rem;
  }
}
.ant-modal {
  .ant-modal-content {
    position: relative;
    .ant-modal-close {
      margin-right: 3rem;
      margin-top: 3rem;
      height: fit-content;
      width: fit-content;
      .ant-modal-close-x {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        margin-right: 0;
        margin-top: 0;
        .anticon-close {
          svg {
            width: 2rem;
          }
        }
      }
    }
    .ant-modal-body {
      .experience-modal {
        padding: 3rem;
        background-color: #f6f8ff;
        border-radius: 1rem;
        &__heading {
          font-size: 2.2rem;
          font-family: $font-primary-bold;
          margin-bottom: 3rem;
          text-align: center;
          line-height: normal;
        }
        &__form-fields {
          .modal-form {
            .ant-form-item {
              margin-bottom: 2rem;
              @include respond(phone-x-small) {
                margin-bottom: 0;
              }
              .ant-form-item-label {
                padding-bottom: 0;
                label {
                  font-size: 1.6rem;
                  font-family: $font-primary;
                  height: auto;
                }
              }
              .ant-form-item-control {
                .ant-form-item-control-input {
                  .ant-form-item-control-input-content {
                    .ant-input {
                      padding: 0.4rem 1rem;
                      font-size: 1.6rem;
                      font-family: $font-primary;
                      margin-bottom: 0.5rem;
                    }
                    .error {
                      width: auto;
                      font-size: 1.4rem;
                      font-family: $font-primary;
                      color: red;
                      line-height: normal;
                    }
                    .organization-check {
                      display: flex;
                      align-items: center;
                      margin-top: 1rem;
                      font-size: 1.6rem;
                    }
                    .ant-select {
                      text-align: left;
                    }
                    .description-textarea {
                      resize: none;
                    }
                  }
                }
              }
            }
          }
          .ant-btn {
            border: none;
            font-size: 1.6rem;
            font-family: $font-secondary-bold;
            cursor: pointer;
            background-color: $color-primary;
            color: $color-white;
            transition: all 0.2s;
            height: 4rem;
            width: 10rem;
            margin-top: 3rem;
            margin-left: auto;
            display: block;
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
              border: none;
            }
            span {
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
</style>
