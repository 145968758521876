<template>
  <div class="my-collection">
    <div class="my-collection__body">
      <h1
        v-if="artilcesFolders == 0 && !searchingFolders"
        class="no-saved-folder-message"
      >
        No Saved Folders Found
      </h1>
      <a-row :gutter="18" v-if="searchingFolders">
        <a-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="8"
          :xl="8"
          v-for="index in 3"
          :key="index"
        >
          <article-card-skeleton></article-card-skeleton>
        </a-col>
      </a-row>
      <a-tabs v-model:activeKey="activeKey" @change="changeTab" v-else>
        <a-tab-pane
          v-for="(folder, index) in artilcesFolders"
          :key="(index, folder.id)"
        >
          <template #tab>
            <div class="tab-name">
              <p>{{ folder.folder_name }}</p>
              <a-dropdown
                :trigger="['click']"
                placement="bottomRight"
                class="dropdown"
              >
                <a class="ant-dropdown-link" @click.stop>
                  <i class="icon icon-vertical_three-dots"></i>
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="0">
                      <a @click="editArticleFolder(folder)">Edit</a>
                    </a-menu-item>
                    <a-menu-item key="0">
                      <a class="remove-btn" @click="deleteArticleFolder(folder)">Remove</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
          <a-row :gutter="[24, 80]" v-if="searchingArticles" class="mt-3">
            <a-col
              :xs="24"
              :sm="24"
              :md="12"
              :lg="8"
              :xl="8"
              v-for="index in 3"
              :key="index"
            >
              <article-card-skeleton></article-card-skeleton>
            </a-col>
          </a-row>

          <article-slider
            v-else
            :folderId="folder.id"
            :no="3"
            :saveArticles="savedArticleData"
            :showUnsaveBtn="true"
          ></article-slider>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
  <edit-article-folder
    :visible="visibleEditFolderModal"
    @handle-close="visibleEditFolderModal = false"
    :selectedFolderName="selectedFolderName"
    :selectedFolderId="folderId"
    :loading="loader"
    @edit-record="editFolderName"
  ></edit-article-folder>
  <delete-modal
    :visible="showFolderDeleteModal"
    @handle-close="showFolderDeleteModal = false"
    title="Are you sure you want to delete this folder?"
    confirmText="Yes, Delete"
    cancelText="No, Don't Delete"
    :loading="loader"
    @delete-record="deleteFolder"
  ></delete-modal>
  <invite-to-saved-folder
    :visibleModal="visibleInviteModal"
    @close-modal="closeInviteModal"
    :folderId="folderId"
  ></invite-to-saved-folder>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import ArticleSlider from "../../BaseComponents/ArticleSlider.vue";
import EditArticleFolder from "./EditModal.vue";
import DeleteModal from "../../BaseComponents/DeleteModal.vue";
import InviteToSavedFolder from "../../BaseComponents/InviteToSavedFolder.vue";
import MessageToaster from "../../BaseComponents/MessageToaster.vue";
import ArticleCardSkeleton from "../../BaseComponents/ArticleCardSkeleton.vue";
import contentManageMixin from "../../../mixins/contentManage.mixin";

export default {
  components: {
    ArticleSlider,
    EditArticleFolder,
    DeleteModal,
    InviteToSavedFolder,
    MessageToaster,
    ArticleCardSkeleton,
  },
  data() {
    return {
      activeKey: null,
      searchingFolders: false,
      colored: "colored",
      visibleEditFolderModal: false,
      selectedFolderName: "",
      folderId: "",
      loader: false,
      showFolderDeleteModal: false,
      searchingArticles: false,
      articleData: [],
      visibleInviteModal: false,
      showMessageModal: false,
      message: {},
    };
  },
  mixins: [contentManageMixin],
  computed: {
    // artilcesFolders: {
    //   get() {
    //     return this.$store.getters["article/getArticlesFolders"];
    //   },
    //   set(val) {
    //     this.$store.commit("article/setSaveArticleFolders", val);
    //   },
    // },
    savedArticleData() {
      return this.articleData;
    },
  },
  mounted() {
    this.getArticlesFolders();
    this.emitter.on("articleUnsaved", this.handleArticleUnsaved);
  },
  watch: {
    artilcesFolders: {
      handler(newFolders) {
        if (newFolders.length > 0) {
          this.activeKey = newFolders[0].id.toString();
          this.getSavedArticle(newFolders[0].id,this.$route.params.id);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getArticlesFolders() {
      this.searchingFolders = true;
      try {
        const payload={
          user_id:this.$route.params.id,
        }
        await this.$store.dispatch("article/fetchSaveArticleFolder",payload);
      } catch (err) {
        console.log(err);
      }
      this.searchingFolders = false;
    },
    editArticleFolder(folder) {
      this.selectedFolderName = folder.folder_name;
      this.folderId = folder.id;
      this.visibleEditFolderModal = true;
    },
    async editFolderName(updatedFolder) {
      try {
        const payload = {
          folder_id: this.folderId,
          connection_list: updatedFolder.connectionList,
          folder_name: updatedFolder.folderName,
        };
        this.loader = true;
        const response = await this.$store.dispatch(
          "article/updateArticleFolder",
          payload
        );
        if (response.status === 200) {
          this.artilcesFolders.filter((val) => {
            if (val.id == this.folderId) {
              val.folder_name = updatedFolder.folderName;
              this.visibleEditFolderModal = false;
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
      this.loader = false;
    },
    deleteArticleFolder(folder) {
      this.folderId = folder.id;
      this.showFolderDeleteModal = true;
    },
    async deleteFolder() {
      try {
        this.loader = true;
        const response = await this.$store.dispatch(
          "article/deleteArticleFolder",
          this.folderId
        );
        if (response.status === 204) {
          const updatedList = this.artilcesFolders.filter((val) => {
            return val.id !== this.folderId;
          });
          this.artilcesFolders = updatedList;
          this.showFolderDeleteModal = false;
        }
      } catch (err) {
        console.log(err);
      }
      this.loader = false;
    },
    // async getSavedArticle(folderId) {
    //   this.folderId = folderId;
    //   try {
    //     const payload = {
    //       folder_id: folderId,
    //     };

    //     let folderExists = -1;
    //     // fetch the article index
    //     folderExists = this.articleData.findIndex(
    //       (article) => article.id === folderId
    //     );

    //     //API calls were being made on collapse down and up in order to eliminate the problem of excess API calls
    //     // we are first finding the index, then if the index  > -1 we don't make the api call as the saved articles already exists
    //     if (folderExists < 0) {
    //       this.searchingArticles = true;
    //       this.$store
    //         .dispatch("article/fetchSavedArticlesList", payload)
    //         .then((response) => {
    //           if (response.status === 200) {
    //             this.articleData.push(response.data);
    //             this.searchingArticles = false;
    //           }
    //         });
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
    handleArticleUnsaved() {
      // Decrease the count for the respective folder
      const folderIndex = this.artilcesFolders.findIndex(
        (folder) => folder.id === this.folderId
      );
      if (folderIndex !== -1) {
        this.artilcesFolders[folderIndex].total_save_articles -= 1;
      }
    },
    closeInviteModal(val, messageModal, type) {
      this.visibleInviteModal = val;
      if (type == "success") {
        this.message = {
          title: "Successfully invited on folder",
          type: "success",
        };
      } else {
        this.message = {
          title: type,
          type: "failure",
        };
      }
      this.showMessageModal = messageModal;
    },
    async getFolderComments() {
      if (this.folderId) {
        try {
          this.$store.dispatch(
            "profile/fetchSavedFolderComments",
            this.folderId
          );
        } catch (err) {
          console.log(err);
        }
      }
    },
    changeTab(folderId) {
      this.getSavedArticle(folderId,this.$route.params.id);
    },
  },
};
</script>

<style lang="scss">
.my-collection {
  padding: 3.5rem 3.5rem 2.5rem 3.5rem;
  @include respond(laptop-small) {
    padding: 3rem 0;
  }
  //override ant-collapse styles
  .no-saved-folder-message {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 2rem;
    color: $color-black;
    font-family: $font-primary;
    margin-top: 4rem;
  }
  &__body {
    .ant-tabs {
      .ant-tabs-bar {
        border-bottom: 1px solid $color-dark-grey-5;
        margin: 0;
        .ant-tabs-nav-container {
          padding: 0;
          .ant-tabs-tab-prev,
          .ant-tabs-tab-next {
            display: none !important;
          }
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-scroll {
              overflow-x: auto;
              @include respond(phone-x-small) {
                &::-webkit-scrollbar {
                  display: none !important;
                }
              }
              &::-webkit-scrollbar {
                height: 0.7rem;
              }

              &::-webkit-scrollbar-track {
                background-color: $color-white;
                border-radius: 0.8rem;
                height: 0.7rem;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $color-primary;
                border-radius: 0.8rem;
              }
              .ant-tabs-nav {
                width: 100%;
                transform: none !important;
                .ant-tabs-tab {
                  background-color: $color-light-grey-3;
                  padding: 1rem 1.6rem;
                  border-radius: 100px;
                  margin-right: 1.6rem;
                  border-top-right-radius: 1rem !important;
                  border-top-left-radius: 1rem !important;
                  border-bottom-left-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  margin-bottom: 0rem;
                  .tab-name {
                    display: flex;
                    align-items: center;
                    p {
                      font-size: 1.4rem;
                      line-height: 1.6rem;
                      font-family: $font-primary-medium;
                      color: $color-dark-grey-6;
                      margin-bottom: 0;
                      @include respond(tab-port) {
                        font-size: 1.6rem;
                      }
                    }
                    .dropdown {
                      margin-left: 1rem;
                      margin-top: 0.2rem;
                      .icon {
                        color: $color-black;
                        font-size: 1.5rem;
                        margin-left: 0;
                        @include respond(tab-port) {
                          font-size: 1.6rem;
                        }
                      }
                    }
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                }
                .ant-tabs-tab-active {
                  color: $color-white;
                  background-color: $color-primary;
                  font-family: $font-primary-medium;
                  text-shadow: none;
                  .tab-name {
                    p {
                      color: $color-white;
                    }
                    .dropdown {
                      .icon {
                        color: $color-white;
                      }
                    }
                  }
                }
                .ant-tabs-ink-bar {
                  display: none !important;
                }
              }
              .ant-tabs-nav > div {
                display: flex;
                align-items: flex-end;
                height: 4rem;
              }
            }
          }
        }
      }
      .ant-tabs-top-content {
        .ant-tabs-tabpane {
          overflow: hidden;
          padding: 0 0;
          min-height: 40rem;
          .mt-3 {
            margin-top: 3rem;
          }
        }
      }
    }
  }
}
</style>
