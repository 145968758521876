<template>
<div class="portfolio-articles">
  <a-row :gutter="[40, 18]">
    <a-col :xs="24" :sm="10" :md="10" :lg="6" :xl="6" class="categories-side-bar">
      <div class="categories-side-bar__navbar">
        <h1 class="categories-side-bar__navbar--heading">Categories</h1>
          <div v-if="foldersLoader">
          <a-col
            :xs="24"
            :sm="14"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <a-skeleton
              class="sidebar-category-skeleton"
              active
              :title="{ width: '100%' }"
              :paragraph="{ rows: 3, width: ['100%', '100%', '100%'] }"
            />
          </a-col>
        </div>
        <draggable v-if="!foldersLoader" v-model="items" class="categories-side-bar__navbar--list">
          <template v-slot:item="{item}">
            <div
              class="item"
              :class="{ clicked: isActive(item.folder_name) }"
              @click="clickNav(item.folder_name)"
              @drop="onUpdate(item)"
            >
              <p>{{ item.folder_name }} <span class="count">{{ formatTotal(item.article_count) }}</span></p>
            </div>
          </template>
        </draggable>
      </div>
    </a-col>
    <transition name="nav-content" mode="out-in">
      <a-col
        v-if="selectedTab == 'Shared Articles'"
        :xs="24"
        :sm="14"
        :md="14"
        :lg="18"
        :xl="18"
        class="content"
      >
        <div
          v-if="articles.length == 0 && !skeletonLoading"
          class="message-results-not-found"
        >
          <p>No shared articles</p>
        </div>
        <div v-else class="article-wrapper">
          <a-row :gutter="18" v-if="skeletonLoading">
            <a-col
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="8"
              v-for="index in 3"
              :key="index"
            >
              <a-skeleton
                class="article-card-skeleton"
                active
                :title="{ width: 'full' }"
                :paragraph="{ rows: 3, width: ['full'] }"
              />
            </a-col>
          </a-row>
          <a-row type="flex" :gutter="[18, 18]" v-else>
            <a-col
              v-for="article in articles" :key="article"
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="8"
            >
              <article-card
                :title="article.article.title"
                :author-name="article.article.authors"
                :text="article.article.description"
                :image="article.article.image"
                :sourceLogo="article.logo"
                :source="article.article.article_url.source"
                :profile-photo="'profile-pic.webp'"
                :url="article.article.article_url.url"
                :id="article.article.id"
                :date="article.article.pub_date"
                :ratings="article.article.rating"
                :aggregatedRating="article.article.aggregated_rating"
                :userReaction="article.article.user_reaction"
                :totalArticleReactions="article.article.article_reactions"
                :userReactionRepresentation="article.article.users_reaction_representation"
                :showShareButton="false"
                :showSaveBtn="true"
                :showDltBtn="showDeleteButton"
                @getOrder="getAllCategories"
              ></article-card>
            </a-col>
          </a-row>
        </div>
        <the-pagination
          v-if="shareArticlesPagination"
          :total="totalShareArticles"
          :itemsPerPage="6"
          @change-page="changePageShareArticles($event)"
         ></the-pagination>
      </a-col>
    </transition>
   <transition name="nav-content" mode="out-in">
      <a-col
        v-if="selectedTab == 'Highlighted Current Events'"
        :xs="24"
        :sm="14"
        :md="14"
        :lg="18"
        :xl="18"
        class="content"
      >
        <highlighted-current-events></highlighted-current-events>
      </a-col>
    </transition> 
    <transition name="nav-content" mode="out-in">
      <a-col
        v-if="selectedTab == 'Rated Articles'"
        :xs="24"
        :sm="14"
        :md="14"
        :lg="18"
        :xl="18"
        class="content"
      >
        <div
          v-if="ratedArticles == 0 && !searchingRatedArticle"
          class="message-results-not-found"
        >
          <p>No rated articles</p>
        </div>
        <div v-else class="article-wrapper">
          <a-row :gutter="18" v-if="searchingRatedArticle">
            <a-col
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="8"
              v-for="index in 3"
              :key="index"
            >
              <a-skeleton
                class="article-card-skeleton"
                active
                :title="{ width: 'full' }"
                :paragraph="{ rows: 3, width: ['full'] }"
              />
            </a-col>
          </a-row>
          <a-row type="flex" :gutter="[18, 18]" v-else>
            <a-col
              v-for="article in ratedArticles" :key="article.id"
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="8"
            >
            <article-card
              :title="article.title"
              :author-name="article.authors"
              :text="article.description"
              :image="article.image"
              :source="article.article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="article.article_url.url"
              :id="article.id"
              :date="article.pub_date"
              :ratings="article.rating"
              :aggregatedRating="article.aggregated_rating"
              :userReaction="article.user_reaction"
              :totalArticleReactions="article.article_reactions"
              :userReactionRepresentation="article.users_reaction_representation"
              :showShareButton="false"
              :showSaveBtn="false"
              :showDltBtn="false"
              @getOrder="getAllCategories"
            ></article-card>
            </a-col>
          </a-row>
        </div>
        <the-pagination
          v-if="ratedArticlesPagination"
          :total="totalRatedArticles"
          :itemsPerPage="6"
          @change-page="changePageRatedArticles($event)"
         ></the-pagination>
      </a-col>
      
    </transition>
    <transition name="nav-content" mode="out-in">
      <a-col
        v-if="selectedTab == 'Saved Articles'"
        :xs="24"
        :sm="14"
        :md="14"
        :lg="18"
        :xl="18"
        class="content"
      >
      <div>
      <saved-article></saved-article>
      </div>
      </a-col>
    </transition>
  </a-row>
</div>
</template>

<script>
import ArticleCard from "../../BaseComponents/ArticleCard.vue";
import HighlightedCurrentEvents from "../HighlightedCurrentEvent/HighlightedCurrentEvents.vue";
import ThePagination from "../../BaseComponents/ThePagination.vue";
import SavedArticle from "../SavedArticle/SavedArticle.vue";
import Draggable from "vue3-draggable";
import contentManageMixin from "../../../mixins/contentManage.mixin";
import  { mapGetters, mapActions } from "vuex"

export default {
  components: { 
    ArticleCard, 
    HighlightedCurrentEvents,
    ThePagination,
    SavedArticle,
    Draggable
  },
  data() {
    return {
      selectedTab: '',
      colored: "colored",
      showDeleteButton: true,
      showUnsaveButton: true,
      showSavedArticlesButton: false,
      skeletonLoading: false,
      searchingRatedArticle: false,
      searchingSavedArticle: false,
      foldersLoader: false,
      items: [],
      itemId: ""
    }
  },
  mixins: [contentManageMixin],
  async mounted() { 
    await this.getAllCategories();
    // this.getShareArticle();
  },
  computed: {
    ...mapGetters({
      reactedArticle:'article/getReactedArticle'
    }),
    articles() {
      return this.$store.getters["article/getShareArticle"];
    },
    totalShareArticles() {
      return this.$store.getters["article/getShareTotalArticles"];
    },
    shareArticlesPagination() {
      return (
        this.$store.getters["article/getShareArticleNextUrl"] ||
        this.$store.getters["article/getShareArticlePreviousUrl"]
      );
    },
    ratedArticles() {
      return this.$store.getters["article/getRatedArticle"];
    },
    totalRatedArticles() {
      return this.$store.getters["article/getRatedTotalArticles"];
    },
    ratedArticlesPagination() {
      return (
        this.$store.getters["article/getRatedArticleNextUrl"] ||
        this.$store.getters["article/getRatedArticlePreviousUrl"]
      );
    },
    
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) { 
        if (to.query.portfolio_tab) { 
          this.selectedTab = to.query.portfolio_tab
        }
      }
    },
    // "selectedTab"(tab){
    //   if(tab==='Rated Articles'){
    //     this.getRatedArticles()
    //   } else if(tab==='Shared Articles'){
    //     this.getShareArticles()
    //   }
    // },
    selectedTab: {
    handler(tab) {
      console.log("THIS IS TAB", tab)
      if (tab === 'Rated Articles') {
        this.getRatedArticles();
      } else if (tab === 'Shared Articles') {
        this.getShareArticles();
      }
    },
    immediate: true,
  },
    "$route.params.id"(newId, oldId) {
      if (newId !== oldId) {
        this.$store.commit('article/clearRatedArticles');
        this.selectedTab = ""
        this.getAllCategories();
      }
    },
    items(val) {
      const order = val.findIndex((findItem) => findItem.id === this.itemId );
      const newOrder = order + 1;
      if(this.itemId){
      this.updateOrdering(newOrder);
      }
    },
    reactedArticle:{
      handler(article){
        this.updateContentManagementArticles({'articleType':this.selectedTab, 'article':article?.article})
      }
    }
  },
  methods: {
    ...mapActions({
      updateContentManagementArticles:'article/updateContentManagementArticles'
    }),
    async getShareArticles() {
      try {
        this.skeletonLoading = true;
       await this.getShareArticle()
      } catch (error) {
        console.log(error);
      } finally {
        this.skeletonLoading = false;
      }
    },
   async getRatedArticles(){
      try {
        this.searchingRatedArticle = true;
        await this.getRatedArticle()
      } catch (error) {
        console.log(error);
      } finally {
        this.searchingRatedArticle = false;
      }
    },
    clickNav(tab) {
      this.selectedTab = tab;
      this.$emit("changeTab", this.selectedTab);
    },
    isActive(tab) {
      return this.selectedTab == tab;
    },
    onUpdate(newOrder){
      this.itemId = newOrder.id;
    },
    async getAllCategories() {
      this.foldersLoader = true;
      try {
        const payload = {
          user_id: this.$route.params.id,
        };
        const response = await this.$store.dispatch("profile/fetchfolderCategories",payload);
        if(response.status === 200){
          // console.clear()
          // console.log('working');
          
          this.items = response.data;
          this.selectedTab = this.items[0].folder_name;
        }
        response.data.forEach((folderName, index)=>{
          if(folderName.folder_name === 'Highlighted Current Events') {
            response.data.splice(index, 1)
          }
        })
      } catch (err) {
        console.log(err);
      }
      this.foldersLoader = false;
    },
    async updateOrdering(order) {
      try {
        const payload = {
          order: order,
          id: this.itemId,
        }
        await this.$store.dispatch("profile/updateCategoriesOrdering", payload);
      } catch (err) {
        console.log(err);
      }
    },
    // async getShareArticle() {
    //   this.skeletonLoading = true;
    //   try {
    //     const payload = {
    //       user_id: this.$route.params.id,
    //       page: 1,
    //       page_size: 9,
    //     };
    //   const res =  await this.$store.dispatch("article/fetchUserShareArticle", payload);
    //   // console.clear()
    //   console.log('res',res);
      
    //   } catch (err) {
    //     console.log(err);
    //   }
    //   this.skeletonLoading = false;
    // },
    // async getSavedArticles(){
     
    // },
    // getData(){
    //   await this.getShareArticle()
    //   await this.getRatedArticle()
    // },
    // async getRatedArticle() {
    //   this.searchingRatedArticle = true;
    //   try {
    //     const payload = {
    //       user_id: this.$route.params.id,
    //       limit: 6,
    //       offset: 0,
    //     };
    //     await this.$store.dispatch("article/fetchUserRatedArticle", payload);
    //   } catch (err) {
    //     console.log(err);
    //   }
    //   this.searchingRatedArticle = false;
    // },
    async changePageShareArticles(page) {
      this.skeletonLoading = true;
      const payload = {
        user_id: this.$route.params.id,
        page: page,
        page_size: 6,
      };
      await this.$store.dispatch("article/fetchUserShareArticle", payload);
      this.skeletonLoading = false;
    },
    async changePageRatedArticles(page) {
      try {
        this.searchingRatedArticle = true;
      const payload = {
        user_id: this.$route.params.id,
        limit: 6,
        offset: (page - 1) * 6,
      };
      await this.$store.dispatch("article/fetchUserRatedArticle", payload);
      } catch (error) {
        console.log(error);
      } finally {
        this.searchingRatedArticle = false;
      }
    },
    formatTotal(total) {
      if (total < 10) {
        return `0${total}`;
      } else if (total > 99) {
        return '99+';
      } else {
        return total;
      }
    },  
  },
};
</script>

<style lang="scss">
.portfolio-articles {
  padding: 0 2.6rem;
  .categories-side-bar {
    font-family: $font-primary-bold;
    &__navbar {
      background-color: $color-white;
      // margin-bottom: 1rem;
      padding: 0.5rem 0 3rem;
      &--list {
        & > div {
          cursor: grab;
          pointer-events: auto;
          padding: 0;
        }
        .item {
          width: 100%;
          padding: 1.8rem 2rem;
          border-bottom: 1px solid rgba(135, 149, 157, 0.3);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          transition: 0.2s all ease-in-out;
          p {
            font-size: 1.4rem;
            line-height: 1.6rem;
            color: $color-black;
            margin-bottom: 0;
            font-family: $font-primary-regular;
            display: flex;
            align-items: center;
            gap: 1rem;
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
            span {
              opacity: 0.7;
            }
            .count {
              background-color: #ececec;
              color: $color-black;
              font-family: $font-primary-medium;
              font-size: 1.2rem;
              line-height: 1.4rem;
              width: 2.4rem;
              height: 2.4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              opacity: 1;
            }
          }
          &:hover {
            background-color: #ebebeb59;
          }
        }

        .clicked {
          p {
            opacity: 1;
            font-family: $font-primary-medium;
          }
          &::after {
            content: "";
            position: absolute;
            width: 4px;
            height: 100%;
            background-color: $color-primary;
            top: 0;
            right: 0;
          }
        }
      }
      &--heading {
        font-size: 2rem;
        font-family: $font-primary-bold;
        margin-bottom: 4rem;
        padding: 0;
        line-height: 2.3rem;
        @include respond(tab-port) {
          font-size: 2.2rem;
          line-height: 2.8rem;
        }
      }
      .sidebar-category-skeleton {
        padding: 0;
        .ant-skeleton-content {
          .ant-skeleton-title {
            display: none;
          }
          .ant-skeleton-paragraph {
            margin: 1rem 0;
            li {
              height: 3rem;
            }
          }
        }
      }
    }
  }
}
.nav-content-enter-from,
.nav-content-leave-to {
  opacity: 0;
}
.nav-content-enter-active {
  transition: opacity 0.2s ease-out;
}
.nav-content-enter-to,
.nav-content-leave-from {
  opacity: 1;
}
.content {
  .article-wrapper {
    background-color: $color-white;
    position: relative;
    // padding: 3rem;
    @include respond(tab-port) {
      margin-left: 0;
    }
    @include respond(phone-large) {
      margin-left: 0;
    }
    @include respond(phone-x-small) {
      margin-left: 0;
    }
    .the-story-card {
      background-color: #f6f8ff;
    }
    .article-card-skeleton {
      margin-bottom: 3rem;
      .ant-skeleton-content {
        .ant-skeleton-title {
          height: 300px;
          margin-top: 0;
          border-radius: 0;
        }
      }
    }
  }

  .message-results-not-found {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    p {
      font-size: 1.6rem;
      font-family: $font-primary-medium;
    }
  }
}
</style>
