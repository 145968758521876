// import { computed } from "vue";

export default {

    computed: {
        artilcesFolders: {
          get() {
            return this.$store.getters["article/getArticlesFolders"];
          },
          set(val) {
            this.$store.commit("article/setSaveArticleFolders", val);
          },
        },
        // savedArticleData() {
        //   return this.articleData;
        // },
      },
    //   watch: {
    //     artilcesFolders: {
    //       handler(newFolders) {
    //         if (newFolders.length > 0) {
    //           this.activeKey = newFolders[0].id.toString();
    //           console.clear()
    //           console.log('newFolders',newFolders);
              
    //           this.getSavedArticle(newFolders[0].id);
    //         }
    //       },
    //       immediate: true,
    //     },
    //   },

    methods: {
        async getSavedArticle(folderId,userId) {
            this.folderId = folderId;
            try {
              const payload = {
                user_id: userId,
                folder_id: folderId,
              };
      
              let folderExists = -1;
              // fetch the article index
              folderExists = this.articleData.findIndex(
                (article) => article.id === folderId
              );
      
              //API calls were being made on collapse down and up in order to eliminate the problem of excess API calls
              // we are first finding the index, then if the index  > -1 we don't make the api call as the saved articles already exists
              if (folderExists < 0) {
                this.searchingArticles = true;
                this.$store
                  .dispatch("article/fetchSavedArticlesList", payload)
                  .then((response) => {
                    if (response.status === 200) {
                      this.articleData.push(response.data);
                      this.searchingArticles = false;
                    }
                  });
              }
            } catch (err) {
              console.log(err);
            }
          },
        async getRatedArticle() {
            try {
              // this.searchingRatedArticle = true;
              // console.clear()
              // console.log('Setting isLoading to true',this.searchingRatedArticle);
                const payload = {
                    user_id: this.$route.params.id,
                    limit: 6,
                    offset: 0,
                };
                await this.$store.dispatch("article/fetchUserRatedArticle", payload);
            } catch (err) {
                console.log(err);
            } 
            // finally {
            //   this.searchingRatedArticle = false;
            // }
        },
        async getShareArticle() {
            try {
              // this.skeletonLoading = true;
                const payload = {
                    user_id: this.$route.params.id,
                    page: 1,
                    page_size: 9,
                };
                await this.$store.dispatch("article/fetchUserShareArticle", payload);
            } catch (err) {
                console.log(err);
            } 
            // finally {
            //   this.skeletonLoading = false;
            // }
        },
    },
};
